import React from 'react';

import './Year2022.css'

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
        <iframe
            width="560"
            height="315"
            src={`https://www.youtube-nocookie.com/embed/${embedId}`}
            title="God jul"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen />
    </div>
);

const Year2022 = () => {
    return (
        <div className="Year2022">
            <YoutubeEmbed embedId="VV0njMesSCw" />
        </div>
    );
};

export default Year2022;