import React, { useEffect, useState, useRef } from 'react';

import './Year2023.css'

const Year2023 = () => {
    var container = document.querySelector('#contain');
    var containerRef = useRef(null);
    const [height, setHeight] = useState(container?.offsetHeight);
    // First call to define "parchment" height
    useEffect(() => {
        const listener = () => setHeight(containerRef.current?.offsetHeight);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, []);

    useEffect(() => {
        setHeight(containerRef.current?.offsetHeight);
    }, [containerRef]);

    return (
        <>
            <main>
                <div id="parchment" style={{ height: `${height}px` }}></div>
                <div id="contain" ref={containerRef}>
<p>Det var bättre förr.</p>
<p>Tänker jag när någon bankar på min dörr.</p>
<p>Där står en maskerad man.</p>
<p>Förr blev jag uppspelt, numera misstänksam.</p>
<p>Han skulle kunna vara ett hot.</p>
<p>Jag tar upp mitt bowlingklot.</p>
<p>Jag öppnar dörren försi... "Finns det några snälla barn här?"</p>
<p>Nej, inte här. Här finns bara misär.</p>
<p>Och mycket möda och stort besvär.</p>
<p>Nej nej nej, tomtegubbe jag blir galen.</p>
<p>Så snälla låt mig bara vara.</p>
<p></p>
<p>"Oj, vad har hänt?" frågar mannen.</p>
<p>Har du bråkat med grannen?</p>
<p>Har du blivit slagen av stentroll?</p>
<p>Har ditt lag förlorat i fotboll?</p>
<p>Du behöver inte svara, det spelar ingen roll.</p>
<p>Du ska inte behöva leva ditt liv i moll.</p>
<p>Jag ska visa dig julens mirakel.</p>
<p>Tro det eller ej, det handlar inte bara om att ge barn leksaker.</p>
<p>Han viftar med armarna och snön börjar falla.</p>
<p>Han ropar högt "Julen, den är till för oss alla!".</p>
<p>Omkring oss tändas tusen juleljus.</p>
<p>I varje stuga, varje hus.</p>
<p>Det börjar ändå bli rätt mysigt.</p>
<p>Tomtens budskap framgår tydligt.</p>
<p>Vintern må vara både mörk och kall.</p>
<p>Men när julen är kommen och tomten står i vår hall.</p>
<p>Tränger sig ljuset och värmen genom allt.</p>
<p>Att umgås med släkt och vänner är ändå rätt ballt.</p>
<p></p>
<p>Idag hoppas jag du har riktigt kul.</p>
<p>Jag önskar dig en riktigt god jul!</p>
                </div>
            </main>

            <svg>
                <filter id="wavy2">
                    <feTurbulence x="0" y="0" baseFrequency="0.02" numOctaves="5" seed="1" />
                    <feDisplacementMap in="SourceGraphic" scale="20" />
                </filter>
            </svg>
        </>
    );
};

export default Year2023;