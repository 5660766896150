import React from 'react';
import tonkow from "./tonkow.webp";
import princess from "./princess.jpg";
import gold from "./gold.jpg";

const Pics = () => {
   
    return (
        <>
        <img alt="" src={tonkow} />
        <img alt="" src={princess} />
        <img alt="" src={gold} />
        </>
    );
};

export default Pics;