import React from 'react';
import {  createBrowserRouter, RouterProvider } from "react-router-dom";

// import Year2020 from './Year2020';
// import Year2021 from './Year2021';
import Year2022 from './Year2022';

import './App.css';
import Year2023 from './Year2023';
import Pics from './Pics';

const Countdown = () => (<div className="App"><p className="App-countdown">{Math.ceil((new Date(2024, 11, 24) - Date.now()) / (1000 * 60 * 60 * 24))}</p></div>);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Countdown />
  },
  {
    path: "/2023",
    element: <Year2023 />
  },
  {
    path: "/2022",
    element: <Year2022 />
  },
  {
    path: "/picsoritdidnthappen",
    element: <Pics />
  }
])

const App = () => <RouterProvider router={router} />;

export default App;
